

















import Vue from 'vue';
import GridButton from '../Common/GridButton.vue';
import { EventBus } from '../../EventBus/EventBus';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import ProfileImage from '../../UserProfile/ProfileImage.vue';

export default Vue.extend({
  name: 'ProfileImageStep',
  components: { GridButton, GridButtonLayout, ProfileImage },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {
    emitStepForward(): void {
      EventBus.$emit('step-forward');
    },
  },
});
