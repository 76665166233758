



























































































































































import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { mixpanel } from '@/plugins/mixpanel/index';

export default Vue.extend({
  name: 'Home',
  data() {
    return {};
  },
  computed: {
    cardData: {
      get() {
        return [
          {
            title: this.$t('homepage.chooseUs.innovativeResources.flashcards'),
            imgSrc: require('../assets/img/feature_1.jpg'),
          },
          {
            title: this.$t('homepage.chooseUs.innovativeResources.reports'),
            imgSrc: require('../assets/img/feature_2.jpg'),
          },
          {
            title: this.$t('homepage.chooseUs.innovativeResources.tools'),
            imgSrc: require('../assets/img/feature_3.jpg'),
          },
        ];
      },
    },
    chooseUsData: {
      get(): { title: TranslateResult; text: TranslateResult; class: string }[] {
        return [
          {
            title: this.$t('homepage.chooseUs.tailoredLessons.title'),
            text: this.$t('homepage.chooseUs.tailoredLessons.text'),
            class: 'fas fa-circle fa-language',
          },
          {
            title: this.$t('homepage.chooseUs.immersion.title'),
            text: this.$t('homepage.chooseUs.immersion.text'),
            class: 'fas fa-circle fa-headphones',
          },
          {
            title: this.$t('homepage.chooseUs.dataAnalytics.title'),
            text: this.$t('homepage.chooseUs.dataAnalytics.text'),
            class: 'fas fa-chart-bar',
          },
          {
            title: this.$t('homepage.chooseUs.community.title'),
            text: this.$t('homepage.chooseUs.community.text'),
            class: 'fas fa-hands-helping',
          },
        ];
      },
    },
    testimonialData: {
      get() {
        const testimonialData = [];
        for (let i = 0; i < 4; i++) {
          const index = i + 1;
          const translateKey = `homepage.testimonials.${index}`;
          const testimonial = {
            reviewer: {
              name: this.$t(`${translateKey}.reviewer.name`),
              occupation: this.$t(`${translateKey}.reviewer.occupation`),
              imgSrc: require(`../assets/img/reviewer_${index}.jpg`),
            },
            review: this.$t(`${translateKey}.review`),
          };
          testimonialData.push(testimonial);
        }
        return testimonialData;
      },
    },
  },
  created() {
    mixpanel.track('Landing Page');
  },
});
