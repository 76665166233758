





















import Vue from 'vue';
import { EventBus } from '../../EventBus/EventBus';
import GridBaseLayout from './GridBaseLayout.vue';

type ListItems = { content: unknown; value: unknown }[];

export default Vue.extend({
  name: 'GridRectLayout',
  components: { GridBaseLayout },
  props: {
    stepTitle: {
      type: String,
      default: '',
      required: true,
    },
    emittedValueName: {
      type: String,
      default: '',
      required: true,
    },
    listItems: {
      type: Array,
      default: (): {}[] => [],
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {
    emitStepForward(value: any): void {
      EventBus.$emit('step-forward', {
        value,
        emittedValueName: this.emittedValueName,
      });
    },
  },
});

export { ListItems };
