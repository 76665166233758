
































import Vue from 'vue';
import { mapGetters } from 'vuex';
import BasicAccountDropdownLink from './BasicAccountDropdownLink.vue';

export default Vue.extend({
  name: 'BasicAccountDropdown',
  components: {
    BasicAccountDropdownLink,
  },
  props: {
    innerRouterLinks: {
      required: false,
      type: Array,
    },
    outerRouterLinks: {
      required: false,
      type: Array,
    },
    routerLinkClass: {
      required: false,
      type: String,
    },
    activeRouterLinkClass: {
      required: false,
      type: String,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
    }),
  },
  mounted() {
    return;
  },
  methods: {},
});
