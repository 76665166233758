









































import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { makeQueryStringHandler } from '../../../server/components/usecases/utils/queryStringHandler';
import { StringKeyObject } from '../../../server/types/custom';
const queryStringHandler = makeQueryStringHandler;

export default Vue.extend({
  name: 'TeacherSignup',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {
    stepData: {
      get(): { text: TranslateResult | string }[] {
        return [
          {
            text: 'Sign up for a teacher account now!をクリック、Googleのアカウントでサインアップ',
          },
          {
            text: '資格の有無、話せる言語とレベル、お住まいの国と時間帯、LINEやSkypeやDiscordなどのIDを記入',
          },
          {
            text: '1/1 appointment remainingという部分をクリックし、ご希望の面接の日時を選んでください。',
          },
          {
            text: ' 面接日から２週間後までの予定をカレンダーに１時間単位でドラッグして入れて下さい。',
          },
          {
            text: '面接の日までに、Find a teacher のページから他の先生方を参考に、 アイコン画像、1~2分の自己紹介動画やプロフィールを作成してください。また、名前＋Sensei になるように編集して下さい。(例: Aya Sensei）',
          },
        ];
      },
    },
    routerData: {
      get(): StringKeyObject {
        const state = queryStringHandler.encodeQueryStringObj({
          state: {
            isTeacherApp: true,
          },
        });
        const query = queryStringHandler.parseQueryString(state);
        const routerData = { path: 'signup', query };
        return routerData;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {},
});
