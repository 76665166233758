














































































































import Vue from 'vue';
import { focus } from 'vue-focus';
import { email, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { StringKeyObject } from '../../../server/types/custom';
import { StatusCodes } from 'http-status-codes';
import { makeUserRepository } from '../repositories/user/index';
import { IS_PRODUCTION } from '../../../server/constants';
import { vueTopprogress } from 'vue-top-progress';
const userRepository = makeUserRepository;

export default Vue.extend({
  name: 'UserAuthForm',
  components: { vueTopprogress },
  directives: { focus },
  data() {
    return {
      name: '',
      email: '',
      password: '',
      showPassword: false,
      isLoading: false,
      _focusedInputName: '',
    };
  },
  computed: {
    isSignupPage: {
      get(): boolean {
        const routeName = this.$route.name;
        const isSignupPage = routeName == 'Signup';
        return isSignupPage;
      },
    },
    GOOGLE_AUTH_URL: {
      get: function (): string {
        const GOOGLE_AUTH_URL = IS_PRODUCTION
          ? process.env.VUE_APP_GOOGLE_AUTH_URL!
          : process.env.VUE_APP_GOOGLE_AUTH_URL_DEV!;
        return `${GOOGLE_AUTH_URL}&state=${this.queryState}`;
      },
    },
    queryState: {
      get: function (): string {
        const queryState = (this as any).$route.query.state || '';
        return queryState;
      },
    },
    focusedInputName: {
      get: function (): string {
        const startFocusInputName = this.isSignupPage ? 'name' : 'email';
        const focusedInputName = this.$data._focusedInputName
          ? this.$data._focusedInputName
          : startFocusInputName;
        return focusedInputName;
      },
      set: function (newVal: string): void {
        this.$data._focusedInputName = newVal;
      },
    },
  },
  watch: {
    $route() {
      this.focusedInputName = this.isSignupPage ? 'name' : 'email';
    },
  },
  validations: {
    name: {
      required: requiredIf(function (vm) {
        return vm.isSignupPage;
      }),
    },
    email: {
      required,
      email,
    },
    password: {
      required,
      minLength: minLength(8),
    },
  },
  methods: {
    togglePasswordView(): void {
      this.showPassword = !this.showPassword;
    },
    async handleAuthFormSubmit(): Promise<void> {
      this.$v.$touch();
      if (!this.$v.$invalid && !this.isLoading) {
        await this._handleLogin();
      }
    },
    async _handleLogin(): Promise<void> {
      let payload: StringKeyObject = {
        email: this.email,
        password: this.password,
      };
      let endpoint = '/users/auth/base/login';
      this.isLoading = true;
      (this as any).$refs.topProgress.start();
      if (this.isSignupPage) {
        payload = { ...payload, name: this.name };
        endpoint = '/users';
      }
      await this._authorizeUser({ payload, endpoint });
      this.isLoading = false;
      (this as any).$refs.topProgress.done();
      this.$router.push('/dashboard');
    },
    async _authorizeUser(props: { payload: StringKeyObject; endpoint: string }): Promise<void> {
      const { payload, endpoint } = props;
      const query = this.queryState
        ? {
            state: this.queryState,
          }
        : {};
      try {
        this.$store.dispatch('user/resetEntityState');
        await userRepository.create({
          payload,
          customResourcePath: endpoint,
          query,
        });
      } catch (err: any) {
        const hasErrorResponse = err.response;
        const httpStatusCode = hasErrorResponse ? err.response.status : undefined;
        let apiErrorMsgLocale = 'error.general';
        if (httpStatusCode == StatusCodes.CONFLICT) {
          apiErrorMsgLocale = 'error.userAuth.login.emailExists';
        } else if (httpStatusCode == StatusCodes.UNAUTHORIZED) {
          apiErrorMsgLocale = 'error.userAuth.login.invalid';
        }
        (this as any).$refs.topProgress.done();
        this.isLoading = false;
        throw new Error(apiErrorMsgLocale);
      }
    },
    async handleGoogleLogin(): Promise<void> {
      location.href = this.GOOGLE_AUTH_URL;
    },
  },
});
