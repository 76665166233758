








import Vue from 'vue';

export default Vue.extend({
  name: 'PricePill',
  components: {},
  props: {
    price: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
