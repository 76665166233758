


















import Vue from 'vue';

export default Vue.extend({
  name: 'BasicAccountDropdownLink',
  components: {},
  props: {
    routerLinks: {
      required: false,
      type: Array,
    },
    divClass: {
      required: false,
      type: String,
      default: 'py-4 lg:py-3 lg:px-3 inline-block no-underline w-full h-full text-gray-500',
    },
    routerLinkClass: {
      required: false,
      type: String,
      default: '',
    },
    activeRouterLinkClass: {
      required: false,
      type: String,
      default: 'text-black',
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
