




















import Vue from 'vue';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';

export default Vue.extend({
  name: 'AsyncLoader',
  components: { GridButtonLayout },
  props: {
    progressPercent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    progress: {
      get(): number {
        const progress = this.progressPercent >= 100 ? 100 : this.progressPercent;
        return progress;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {},
});
