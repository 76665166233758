






















import Vue from 'vue';
import 'flag-icon-css/css/flag-icon.css';
import FlagIcon from '../../FlagIcon/FlagIcon.vue';
import GridColLayout from '../Layouts/GridColLayout.vue';
import { EventBus } from '../../EventBus/EventBus';

export default Vue.extend({
  name: 'LanguageNameStep',
  components: { FlagIcon, GridColLayout },
  props: {
    stepTitle: {
      type: String,
      default: '',
      required: true,
    },
    languageOfferings: {
      type: Array,
      default: (): {}[] => [],
      required: true,
    },
    emittedValueName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {
    emitStepForward(value: string): void {
      EventBus.$emit('step-forward', { value, emittedValueName: this.emittedValueName });
    },
  },
});
