













import Vue from 'vue';

export default Vue.extend({
  name: 'App',
  computed: {},
  async created() {
    const promises = [
      this.$store.dispatch('user/getEntityStateData'),
      this.$store.dispatch('exchangeRate/getEntityStateData'),
      this.$store.dispatch('packageTransaction/getEntityStateData'),
      this.$store.dispatch('appointment/getEntityStateData'),
      this.$store.dispatch('content/getEntityStateData'),
    ];
    await Promise.all(promises);
  },
});
