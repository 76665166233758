


























































































import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { debounce } from 'debounce';
import { makeWordRepository } from '@/repositories/word';
import InfiniteLoading from 'vue-infinite-loading';
import { StringKeyObject } from '@server/types/custom';
import DOMPurify from 'dompurify';
import { ls } from '@/store/plugins';
import { focus } from 'vue-focus';
import TokenizerJa from 'natural/lib/natural/tokenizers/tokenizer_ja';
import LineChart from '@/components/Chart/LineChart.vue';
import { IS_PRODUCTION } from '@server/constants';

const tokenizerJa = new TokenizerJa();
const wordRepository = makeWordRepository;

export default Vue.extend({
  name: 'Tsuginoji',
  components: { InfiniteLoading, LineChart },
  directives: { focus },
  props: {},
  data() {
    return {
      words: [] as any,
      searchWord: '',
      tokenizedSentence: [] as string[],
      query: {
        wordLanguage: 'ja',
        definitionLanguage: 'ja',
        page: 0,
        limit: 100,
      },
      isFocused: true,
      isAutoSearch: true,
      chartOptions: {
        devicePixelRatio: 4,
        responsive: true,
        maintainAspectRatio: false,
        showTooltips: true,
        events: [],
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: false, // this hides the y-axis in the linechart
            },
          ],
        },
        layout: {
          padding: {
            top: 5,
          },
        },
      },
    };
  },
  computed: {
    nonBulletedData: {
      get(): TranslateResult[] {
        const nonBulletedData = [
          'Tsuginoji is a modern Japanese-Japanese dictionary designed for immersion. You can easily find words, their pronunciation, and their pitch-accent.',
          'Enter Japanese text in the search bar or highlight a word and Tsuginoji will fetch data from 三省堂 スーパー大辞林.',
        ];
        return nonBulletedData;
      },
    },
    bulletedData: {
      get(): TranslateResult[] {
        const bulletedData = [
          'Press F to focus on the search bar',
          'Press D to toggle autosearch',
          'Press S to search a highlighted word when autosearch is disabled',
          'We will be adding new features soon, including flashcard integration, saving search data, and more!',
        ];
        return bulletedData;
      },
    },
  },
  watch: {
    searchWord(newWord): void {
      if (newWord == '') {
        this.words = [];
      }
    },
  },
  created() {
    window.addEventListener('keyup', (e: any) => {
      const path = e.path || (e.composedPath && e.composedPath());
      const element = path[0].tagName;
      if (element == 'INPUT') {
        return;
      }
      if (e.key == 'f') {
        this.isFocused = true;
      }
      if (e.key == 'd') {
        this.toggleAutoSearch();
      }
      if (e.key == 's' && !this.isAutoSearch) {
        const text = this.getHighlightedText();
        this.searchText(text);
      }
    });
    const tsuginojiSettings = ls.get('tsuginojiSettings');
    if (tsuginojiSettings) {
      const { isAutoSearch } = tsuginojiSettings;
      this.isAutoSearch = isAutoSearch;
    }
  },
  methods: {
    debounceInput(): void {
      if (this.searchWord == '') {
        this.resetSearch();
        return;
      }
      const self = this;
      (
        debounce(async function () {
          self.query.page = 0;
          const { data } = await wordRepository.get({
            path: `/${self.searchWord}`,
            query: self.query,
          });
          const { words } = data;
          self.words = self.sortWords(words);
        }, 500) as any
      )();
    },
    async onPaginate($state: StringKeyObject): Promise<void> {
      if (this.searchWord) {
        this.query.page++;
        const { data } = await wordRepository.get({
          path: `/${this.searchWord}`,
          query: this.query,
        });
        const { words } = data;
        if (words.length) {
          this.words = this.words.concat(this.sortWords(words));
          $state.loaded();
        } else {
          $state.complete();
        }
      }
    },
    sanitizeHtml(html: string): string {
      const sanitizedHtml = DOMPurify.sanitize(html);
      return sanitizedHtml;
    },
    resetSearch(): void {
      this.searchWord = '';
      this.words = [];
      this.tokenizedSentence = [];
      this.query.page = 0;
    },
    sortWords(words: StringKeyObject[]): StringKeyObject[] {
      const sortedWordArr = words.sort((a, b) => {
        return (
          b.pitch.length - a.pitch.length ||
          a.kana.length - b.kana.length ||
          b.definition.length - a.definition.length
        );
      });
      return sortedWordArr;
    },
    onFocus(e: any): void {
      e.target.select();
      this.isFocused = true;
    },
    toggleAutoSearch(): void {
      this.isAutoSearch = !this.isAutoSearch;
      ls.set('tsuginojiSettings', {
        isAutoSearch: this.isAutoSearch,
      });
    },
    onAutoSearch(): void {
      const text = this.getHighlightedText();
      if (!text || !this.isAutoSearch) {
        return;
      }
      this.searchText(text);
    },
    getHighlightedText(): string | undefined {
      let text = '';
      const documentSelection = (document as any).selection;
      const wndw = window as any;
      if (wndw.getSelection) {
        text = wndw.getSelection().toString();
      } else if (documentSelection && documentSelection.type != 'Control') {
        text = documentSelection.createRange().text;
      }
      return text;
    },
    searchText(text: any): void {
      const tokenizedText: string[] = tokenizerJa.tokenize(text);
      if (tokenizedText.length > 1) {
        this.searchWord = tokenizedText[0];
        this.tokenizedSentence = tokenizedText;
      } else {
        this.searchWord = text;
      }
      this.debounceInput();
    },
    playAudio(url: string): void {
      if (!url) {
        return;
      }
      const audio = new Audio(url);
      audio.play();
    },
    createPitchChartData(props: { kana: string; pitch: number }) {
      const { kana, pitch } = props;
      const moraArr = this._getMora(kana);
      const chartData = {
        labels: moraArr,
        datasets: [] as StringKeyObject[],
      };
      const isHeiban = pitch == 0;
      const isAtamadaka = pitch == 1;
      const isOdaka = pitch == moraArr.length;
      const isNakadaka = !isHeiban && !isAtamadaka && !isOdaka;
      const data = new Array(moraArr.length).fill(!isAtamadaka ? 1 : 0);
      !isAtamadaka ? (data[0] = 0) : (data[0] = 1);
      if (isNakadaka) {
        for (let i = 0; i < data.length; i++) {
          if (i > pitch - 1) {
            data[i] = 0;
          }
        }
      }
      chartData.datasets.push({
        fill: false,
        backgroundColor: '#ffffff',
        borderColor: '#000000',
        pointBorderColor: '#000000',
        data,
      });
      return chartData;
    },
    _getMora(kana: string): string[] {
      const sokuon = [
        'ぁ',
        'ァ',
        'ぃ',
        'ィ',
        'ぅ',
        'ゥ',
        'ぇ',
        'ェ',
        'ぉ',
        'ォ',
        'ゃ',
        'ャ',
        'ゅ',
        'ュ',
        'ょ',
        'ョ',
      ];
      const kanaArr = kana.split('');
      const moraArr: string[] = [];
      for (let i = 0; i < kanaArr.length; i++) {
        const containsSokuon = sokuon.includes(kanaArr[i]);
        if (!containsSokuon) {
          moraArr.push(kanaArr[i]);
        } else {
          moraArr[moraArr.length - 1] = moraArr[moraArr.length - 1] + kanaArr[i];
        }
      }
      moraArr.push('助詞');
      return moraArr;
    },
    styleChart(kana: string) {
      let style = 'height: 60px; max-width:';
      if (kana.length <= 2) {
        return `${style} 130px`;
      } else if (kana.length <= 5) {
        return `${style} 200px;`;
      } else {
        return `${style} 350px;`;
      }
    },
  },
});
