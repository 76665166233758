












































import LanguageBars from '@/components/UserProfile/LanguageBars.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { makeExchangeRateMixin } from '@/mixins/exchangeRate';
import { makeTeacherRepository } from '@/repositories/teacher';
import { JoinedUserDoc } from '@server/models/User';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { StringKeyObject } from '@server/types/custom';
import { mixpanel } from '@/plugins/mixpanel';

const teacherRepository = makeTeacherRepository;

export default Vue.extend({
  name: 'FindTeacher',
  components: { LanguageBars, InfiniteLoading },
  mixins: [makeExchangeRateMixin],
  props: {},
  data() {
    return {
      teachers: [] as JoinedUserDoc[],
      query: {
        page: 0,
        limit: 20,
      },
    };
  },
  computed: {
    ...mapGetters({
      currency: 'user/currency',
    }),
  },
  watch: {
    currency: async function () {
      await this.updateTeacherPrices();
    },
  },
  async created() {
    const { data } = await teacherRepository.get({
      path: '/',
      query: {},
    });
    const { teachers } = data;
    this.teachers = teachers;
    mixpanel.track('View Teachers');
    await this.updateTeacherPrices();
  },
  methods: {
    async updateTeacherPrices() {
      for (const teacher of this.teachers) {
        teacher.teacherData!.priceData = await this.getConvertedPriceData(teacher);
      }
    },
    async getConvertedPriceData(
      teacher: JoinedUserDoc
    ): Promise<{ hourlyRate: number; currency: string }> {
      const priceData = teacher.teacherData!.priceData;
      const { hourlyRate, currency } = priceData;
      const self = this as any;
      const targetCurrency = self.currency;
      const convertedHourlyRate = await self.convert({
        amount: hourlyRate,
        sourceCurrency: currency,
        targetCurrency,
        isRounding: true,
      });
      const convertedPriceData = {
        hourlyRate: convertedHourlyRate,
        currency: targetCurrency,
      };
      return convertedPriceData;
    },
    async onPaginate($state: StringKeyObject): Promise<void> {
      this.query.page++;
      const { data } = await teacherRepository.get({
        path: '/',
        query: this.query,
      });
      const { teachers } = data;
      if (teachers.length) {
        this.query.page++;
        this.teachers = this.teachers.concat(teachers);
        await this.updateTeacherPrices();
        $state.loaded();
      } else {
        $state.complete();
      }
    },
  },
});
