
















import Vue from 'vue';
import { StringKeyObject } from '../../../../server/types/custom';

export default Vue.extend({
  name: 'LanguageBars',
  components: {},
  props: {
    languageLevel: {
      type: String,
      required: true,
    },
    languageCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    languageCodeValue: {
      get(): StringKeyObject {
        const languageCodeValue = {
          A1: 0,
          A2: 1,
          B1: 2,
          B2: 3,
          C1: 4,
          C2: 5,
        };
        return languageCodeValue;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {},
});
