






























































import Vue from 'vue';
import { makeCalendarMixin } from '../../mixins/calendar';
import { mapGetters } from 'vuex';
import { AppointmentDoc } from '@server/models/Appointment';

export default Vue.extend({
  name: 'AppointmentEditor',
  components: {},
  mixins: [makeCalendarMixin],
  props: {
    selectedEventCoord: {
      type: Object,
      default: () => ({ x: 0, y: 0 }),
      required: true,
    },
    selectedEvent: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'user/locale',
      userData: 'user/entityStateData',
    }),
    menuWidth: {
      get(): string {
        const menuWidth = '340px';
        return menuWidth;
      },
    },
    showMenuOnLeft: {
      get(): boolean {
        const windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        const showMenuOnLeft = this.selectedEventCoord.x > windowWidth / 2;
        return showMenuOnLeft;
      },
    },
    showConfirmButton: {
      get(): boolean | undefined {
        if (this.selectedEvent.attributes && this.selectedEvent.attributes.originalEvent.event) {
          const appointment = this.selectedEvent.attributes.originalEvent.event as AppointmentDoc;
          const showConfirmButton =
            appointment.status == 'pending' && appointment.hostedById == this.userData._id;
          return showConfirmButton;
        } else {
          return false;
        }
      },
    },
    showCancelButton: {
      get(): boolean | undefined {
        if (this.selectedEvent.attributes && this.selectedEvent.attributes.originalEvent.event) {
          const appointment = this.selectedEvent.attributes.originalEvent.event as AppointmentDoc;
          const showCancelButton =
            appointment.status != 'cancelled' &&
            appointment.status != 'completed' &&
            appointment.hostedById == this.userData._id;
          return showCancelButton;
        } else {
          return false;
        }
      },
    },
    // showCancelButton: {
    //   get(): boolean {
    //     const appointment = this.selectedEvent.attributes.originalEvent.event as AppointmentDoc;
    //     const showCancelButton =
    //       appointment.status != 'cancelled' && appointment.status != 'completed';
    //     return showCancelButton;
    //   },
    // },
  },
  mounted() {
    return;
  },
  methods: {
    onButtonClick(eventName: string) {
      this.$emit(eventName, this.selectedEvent);
      this.showMenu = false;
    },
  },
});
