





























import Vue from 'vue';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import { EventBus } from '../../EventBus/EventBus';
import GridButton from '../Common/GridButton.vue';
import CurrencyInput from '../../CurrencyInput/CurrencyInput.vue';
import { DEFAULT_CURRENCY } from '../../../../../server/constants';

export default Vue.extend({
  name: 'TeacherPriceDataStep',
  components: { GridButtonLayout, GridButton, CurrencyInput },
  props: {
    isProTeacher: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    hourlyRate: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      teacherHourlyRate: this.hourlyRate || this.userData.teacherData.priceData.hourlyRate,
      currency: DEFAULT_CURRENCY,
    };
  },
  computed: {},
  // watch: {
  //   isProTeacher: function (newVal) {
  //     if (!newVal) {
  //       this.teacherHourlyRate = 30;
  //     }
  //   },
  // },
  mounted() {
    return;
  },
  methods: {
    emitStepForward(): void {
      if (this.teacherHourlyRate || this.teacherHourlyRate >= 0) {
        EventBus.$emit('step-forward', {
          value: this.teacherHourlyRate,
          emittedValueName: 'teacherHourlyRate',
        });
      }
    },
  },
});
