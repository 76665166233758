































import Vue from 'vue';
import GridButton from '../Common/GridButton.vue';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import { focus } from 'vue-focus';

export default Vue.extend({
  name: 'TextInputLayout',
  components: { GridButton, GridButtonLayout },
  directives: { focus },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    inputValue: {
      type: String,
      required: true,
    },
    inputPlaceholderText: {
      type: String,
      required: true,
    },
    inputErrorText: {
      type: String,
      required: true,
    },
    inputModelVuelidateObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    inputModel: {
      get() {
        return (this as any).inputValue;
      },
      set(value) {
        (this as any).$emit('update:prop', value);
      },
    },
  },
  mounted() {
    return;
  },
  methods: {
    handleEnterKeyPress(): void {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('step-forward');
      }
    },
  },
  validations() {
    return this.inputModelVuelidateObj;
  },
});
