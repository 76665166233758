























import Vue from 'vue';
import { JoinedUserDoc } from '../../../../server/models/User';
import { StringKeyObject } from '../../../../server/types/custom';
import LanguageBars from './LanguageBars.vue';
import ProfileImage from './ProfileImage.vue';

export default Vue.extend({
  name: 'ProfileImageCard',
  components: {
    ProfileImage,
    LanguageBars,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    languages: {
      get(): JoinedUserDoc['languages'] {
        const languages = this.userData.languages;
        return languages;
      },
    },
    targetLanguage: {
      get(): StringKeyObject | undefined {
        const languages = this.languages;
        const targetLanguage = this.languages.length > 0 ? languages[0] : undefined;
        return targetLanguage;
      },
    },
    nonTargetLanguage: {
      get(): StringKeyObject | undefined {
        const languages = this.languages;
        const nonTargetLanguage = this.languages.length > 0 ? languages[1] : undefined;
        return nonTargetLanguage;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {},
});
