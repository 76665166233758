















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { PACKAGE_ENTITY_TYPE } from '../../../../../server/components/entities/package/packageEntity';
import { PackageDoc } from '../../../../../server/models/Package';
import { StringKeyObject } from '../../../../../server/types/custom';
import GridButton from '../Common/GridButton.vue';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import { focus } from 'vue-focus';
import { EventBus } from '../../EventBus/EventBus';

type PackageFormData = Pick<
  PackageDoc,
  '_id' | 'isOffering' | 'name' | 'lessonAmount' | 'lessonDurations' | 'type'
>;

export default Vue.extend({
  name: 'TeacherPackagesStep',
  components: { GridButtonLayout, GridButton },
  directives: { focus },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      packages: [] as PackageFormData[],
      editingPackageId: '',
      focusedInputName: '',
      packageErrors: {} as StringKeyObject,
    };
  },
  computed: {
    ...mapGetters({
      teacherPackages: 'user/teacherPackages',
    }),
    lessonDurations: {
      get(): number[] {
        const lessonDurations = [30, 60, 90];
        return lessonDurations;
      },
    },
    PACKAGE_ENTITY_TYPE: {
      get(): StringKeyObject {
        return PACKAGE_ENTITY_TYPE;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {
    emitStepForward(): void {
      let hasPackageError = false;
      let isOfferingPackage = false;
      for (const _id in this.packageErrors) {
        hasPackageError = hasPackageError || this._hasPackageError(_id);
      }
      for (const pkg of this.packages) {
        isOfferingPackage = isOfferingPackage || pkg.isOffering;
      }
      if (!hasPackageError && isOfferingPackage) {
        EventBus.$emit('step-forward', {
          value: this.packages,
          emittedValueName: 'teacherPackages',
        });
      } else if (!isOfferingPackage) {
        throw new Error('error.onboarding.package.isOffering');
      }
    },
    updatePackageDuration(props: { _id: string; lessonDuration: number }): void {
      const { _id, lessonDuration } = props;
      const packageToUpdate = this.getPackageData(_id);
      const lessonDurations = packageToUpdate.lessonDurations;
      const lessonDurationIndex = lessonDurations.indexOf(lessonDuration);
      if (lessonDurationIndex === -1) {
        lessonDurations.push(lessonDuration);
      } else {
        lessonDurations.splice(lessonDurationIndex, 1);
      }
    },
    getPackageData(_id: string): PackageFormData {
      let packageData = this._findPackage({ _id, packageArr: this.packages });
      if (!packageData) {
        const teacherPackage = this._findPackage({ _id, packageArr: this.teacherPackages });
        const { isOffering, name, lessonAmount, lessonDurations, type } = teacherPackage;
        packageData = { _id, isOffering, name, lessonAmount, lessonDurations, type };
        this.packages.push(packageData);
      }
      return packageData;
    },
    _findPackage(props: {
      _id: string;
      packageArr: PackageFormData[] | PackageDoc[];
    }): PackageFormData {
      const { _id, packageArr } = props;
      const pkg = packageArr.find((pkg) => pkg._id == _id)!;
      return pkg;
    },
    updatePackageOffering(event: any, _id: string): void {
      this._updatePackage(event, { _id, propertyName: 'isOffering', eventTargetName: 'checked' });
    },
    _updatePackage(
      event: any,
      props: { _id: string; propertyName: string; eventTargetName: any }
    ): void {
      const { _id, propertyName, eventTargetName } = props;
      const packageToUpdate: StringKeyObject = this.getPackageData(_id);
      packageToUpdate[propertyName] = event.target[eventTargetName];
    },
    updatePackageName(event: any, _id: string): void {
      this._updatePackage(event, { _id, propertyName: 'name', eventTargetName: 'value' });
    },
    updatePackageAmount(event: any, _id: string): void {
      this._updatePackage(event, {
        _id,
        propertyName: 'lessonAmount',
        eventTargetName: 'valueAsNumber',
      });
    },
    setEditingPackageId(props: { _id: string; focusedInputName?: string }): void {
      const { _id, focusedInputName } = props;
      this.editingPackageId = _id;
      this.setFocusedInputName(focusedInputName);
    },
    setFocusedInputName(focusedInputName?: string) {
      this.focusedInputName = focusedInputName || '';
    },
    isEditingPackage(pkg: PackageDoc): boolean {
      const isEditingPackage = this.editingPackageId == pkg._id && this.isCustomPackage(pkg);
      return isEditingPackage;
    },
    isCustomPackage(pkg: PackageDoc) {
      const isCustomPackage = pkg.type == PACKAGE_ENTITY_TYPE.CUSTOM;
      return isCustomPackage;
    },
    isFocused(props: { _id: string; focusedInputName: string }): boolean {
      const { _id, focusedInputName } = props;
      const isFocused = this.editingPackageId == _id && this.focusedInputName == focusedInputName;
      return isFocused;
    },
    savePackage(_id: string) {
      const hasPackageError = this._hasPackageError(_id);
      if (!hasPackageError) {
        this.setEditingPackageId({ _id: '' });
      }
    },
    _hasPackageError(_id: string): boolean {
      let hasPackageError = false;
      for (const property in this.packageErrors[_id]) {
        hasPackageError = hasPackageError || this.packageErrors[_id][property];
      }
      return hasPackageError;
    },
    showError(props: { _id: string; propertyName: string }): boolean {
      const { _id, propertyName } = props;
      const value = (this.getPackageData(_id) as StringKeyObject)[propertyName];
      const isArray = Array.isArray(value);
      const isEmptyArray = value.length == 0;
      const showError = isArray ? isEmptyArray : !value;
      if (!this.packageErrors[_id]) {
        this.packageErrors[_id] = {};
      }
      this.packageErrors[_id][propertyName] = showError;
      return showError;
    },
  },
});
