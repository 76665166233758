













































import Vue from 'vue';
import { JoinedUserDoc } from '../../../server/models/User';
import TeacherPackagesCard from '../components/UserProfile/TeacherPackagesCard.vue';
import ProfileBioCard from '../components/UserProfile/ProfileBioCard.vue';
import { makeUserRepository } from '../repositories/user';
import TwoCardLayout from '@/components/UserProfile/Layouts/TwoCardLayout.vue';
import { makeExchangeRateMixin } from '@/mixins/exchangeRate';
import { mapGetters } from 'vuex';
import { mixpanel } from '@/plugins/mixpanel';
const userRepository = makeUserRepository;

export default Vue.extend({
  name: 'UserProfile',
  components: { ProfileBioCard, TeacherPackagesCard, TwoCardLayout },
  mixins: [makeExchangeRateMixin],
  props: {},
  computed: {
    ...mapGetters({
      currency: 'user/currency',
    }),
    isTeacher: {
      get(): boolean {
        const self = this as any;
        const isTeacher = self.user && 'teacherData' in self.user && self.user.role != 'admin';
        return isTeacher;
      },
    },
    userId: {
      get(): string {
        const userId = this.$route.params.userId;
        return userId;
      },
    },
  },
  asyncComputed: {
    user: {
      async get(): Promise<JoinedUserDoc | undefined> {
        if (this.userId) {
          const { data } = await userRepository.getById({ _id: this.userId, query: {} });
          const { user } = data;
          mixpanel.track('View Teacher', { teacherId: this.userId });
          return user;
        }
      },
    },
    hourlyRate: {
      async get(): Promise<number | undefined> {
        if (this.isTeacher) {
          const self = this as any;
          const priceData = self.user.teacherData.priceData;
          const { hourlyRate, currency } = priceData;
          const convertedHourlyRate = await self.convert({
            amount: hourlyRate,
            sourceCurrency: currency,
            targetCurrency: this.currency,
            isRounding: true,
          });
          return convertedHourlyRate;
        }
      },
    },
  },
  methods: {
    showDialog(): void {
      (this as any).$refs.teacherPackagesCard.showDialog = true;
      mixpanel.track('Checkout', { teacherId: this.userId });
    },
  },
});
