
















































































import Vue from 'vue';
import { StringKeyObject } from '../../../../server/types/custom';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

enum CALENDAR_VIEW {
  DAY = 'day',
  WEEK = 'week',
}

type EventParams = {
  nativeEvent: StringKeyObject;
  event: StringKeyObject;
};

export default Vue.extend({
  name: 'BaseCalendar',
  components: {},
  props: {
    events: {
      default: () => [],
      required: false,
      type: Array,
    },
    calendarClass: {
      default: 'w-screen h-screen text-white',
      required: false,
      type: String,
    },
    calendarFocusDate: {
      default: '',
      required: false,
      type: String,
    },
  },
  data() {
    return {
      calendarView: CALENDAR_VIEW.WEEK,
      isCalendarReady: false,
      currentTime: {
        hour: 0,
        minute: 0,
      },
      intervalTimerId: 0 as any,
    };
  },
  computed: {
    ...mapGetters({
      locale: 'user/locale',
    }),
    calendarViewData: {
      get(): StringKeyObject {
        const calendarViewData = {
          week: {
            text: this.$t('calendar.week'),
            showIfDesktop: true,
            showIfMobile: false,
          },
          day: {
            text: this.$t('calendar.day'),
            showIfDesktop: true,
            showIfMobile: true,
          },
        };
        return calendarViewData;
      },
    },
    today: {
      get(): string {
        const today = dayjs().format('YYYY-MM-DD');
        return today;
      },
    },
    calendarRef: {
      get(): any {
        return this.isCalendarReady ? this.$refs.calendar : null;
      },
    },
    nowY: {
      get(): string {
        return this.calendarRef
          ? (this as any).calendarRef.timeToY(this.currentTime) + 'px'
          : '-10px';
      },
    },
    calendarFocusDateModel: {
      get(): string {
        const calendarFocusDate = this.calendarFocusDate;
        return calendarFocusDate;
      },
      set(value) {
        this.$emit('update:calendar-focus-date', value);
      },
    },
  },
  watch: {
    isMobile: function (newValue) {
      this.onMobile(newValue);
    },
  },
  async created() {
    const isMobile = (this as any).isMobile;
    this.onMobile(isMobile);
    this.intervalTimerId = setInterval(() => {
      this.setCurrentTime();
    }, 5 * 60 * 1000);
    this.setCurrentTime();
  },
  mounted() {
    this.isCalendarReady = true;
  },
  destroyed() {
    clearInterval(this.intervalTimerId);
  },
  methods: {
    onMobile(isMobile: boolean): void {
      isMobile ? (this.calendarView = CALENDAR_VIEW.DAY) : (this.calendarView = CALENDAR_VIEW.WEEK);
    },
    focusDate({ date, calendarView }: { date: string; calendarView: CALENDAR_VIEW }): void {
      this.calendarFocusDateModel = date;
      this.calendarView = calendarView;
      this.$emit('toolbar:click');
    },
    moveCalendar(direction: 'backward' | 'forward'): void {
      const isMovingForward = direction == 'forward';
      const calendar = this.$refs.calendar as any;
      isMovingForward ? calendar.next() : calendar.prev();
      this.$emit('toolbar:click');
    },
    setCurrentTime(): void {
      this.currentTime = {
        hour: dayjs().hour(),
        minute: dayjs().minute(),
      };
    },
    onChange({ start, end }: { start: StringKeyObject; end: StringKeyObject }): void {
      this.$emit('change', { start, end });
    },
    onMouseDownEvent({ event, timed }: { event: StringKeyObject; timed: boolean }): void {
      this.$emit('mousedown:event', { event, timed });
    },
    onMouseDownTime(tms: StringKeyObject): void {
      this.$emit('mousedown:time', tms);
    },
    onMouseMoveTime(tms: StringKeyObject): void {
      this.$emit('mousemove:time', tms);
    },
    onMouseUpTime(): void {
      this.$emit('mouseup:time');
    },
    onMouseUpEvent({ nativeEvent, event }: EventParams): void {
      this.$emit('mouseup:event', { nativeEvent, event });
    },
    onMouseLeaveNative(): void {
      this.$emit('mouseleave.native');
    },
    onClickEvent({ nativeEvent, event }: EventParams): void {
      this.$emit('click:event', { nativeEvent, event });
    },
    onTouchStartEvent({ nativeEvent, event }: EventParams): void {
      this.$emit('touchstart:event', { nativeEvent, event });
    },
    onTouchMoveTime(tms: StringKeyObject): void {
      this.$emit('touchstart:time', tms);
    },
    onTouchStartTime(tms: StringKeyObject): void {
      this.$emit('touchmove:time', tms);
    },
    onTouchEndTime(tms: StringKeyObject): void {
      this.$emit('touchend:time', tms);
    },
    onTouchEndEvent({ nativeEvent, event }: EventParams): void {
      this.$emit('touchend:event', { nativeEvent, event });
    },
  },
});
