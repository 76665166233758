



















































































import Vue from 'vue';
import { makePackageMixin } from '../../mixins/package';
import DialogButton from './DialogButton.vue';
import { makePackageTransactionCheckoutRepository } from '@/repositories/checkout/packageTransaction';
import { StringKeyObject } from '@server/types/custom';
import { ls } from '@/store/plugins';
import { mapGetters } from 'vuex';
import { vueTopprogress } from 'vue-top-progress';
const packageTransactionCheckoutRepository = makePackageTransactionCheckoutRepository;

export default Vue.extend({
  name: 'PaymentCard',
  components: { DialogButton, vueTopprogress },
  mixins: [makePackageMixin],
  props: {
    teacher: {
      type: Object,
      required: true,
    },
    timeslots: {
      type: Array,
      required: true,
    },
    duration: {
      type: Number,
      required: true,
    },
    pkg: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedPaymentGateway: '',
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
    }),
    paymentGateways: {
      get(): StringKeyObject[] {
        const paymentGateways = [
          {
            name: 'paypal',
            iconClass: 'fab fa-paypal',
          },
          {
            name: 'stripe',
            iconClass: 'fas fa-credit-card',
          },
        ];
        return paymentGateways;
      },
    },
    isPaymentButtonDisabled: {
      get(): boolean {
        const isPaymentButtonDisabled = this.selectedPaymentGateway.length == 0;
        return isPaymentButtonDisabled;
      },
    },
  },
  asyncComputed: {
    packagePriceData: {
      async get(): Promise<StringKeyObject> {
        const packagePriceData = await (this as any).getPackagePriceData({
          teacher: this.teacher,
          pkg: this.pkg,
          lessonDuration: this.duration,
          paymentGateway: this.selectedPaymentGateway,
        });
        return packagePriceData;
      },
    },
  },
  created() {
    const paymentData = ls.get('paymentData');
    if (paymentData) {
      const { paymentGateway } = paymentData;
      this.selectedPaymentGateway = paymentGateway;
    }
  },
  methods: {
    onPaymentGatewayClick(paymentGateway: StringKeyObject): void {
      this.selectedPaymentGateway = paymentGateway.name;
    },
    async onPaymentClick(): Promise<void> {
      if (this.isLoggedIn) {
        (this as any).$refs.topProgress.start();
        const packageTransactionCheckoutRes = await packageTransactionCheckoutRepository.create({
          query: {
            paymentGateway: this.selectedPaymentGateway,
          },
          payload: {
            teacherId: this.teacher.teacherData._id,
            packageId: this.pkg._id,
            lessonDuration: this.duration,
            lessonLanguage: this.teacher.teacherData.teachingLanguages[0].code,
            timeslots: this.timeslots,
          },
        });
        (this as any).$refs.topProgress.done();
        const { data } = packageTransactionCheckoutRes;
        const { redirectUrl } = data;
        ls.remove('paymentData');
        ls.set('paymentGatewaySuccess', true);
        window.location.href = redirectUrl;
      } else {
        ls.set('paymentData', {
          teacher: this.teacher,
          timeslots: this.timeslots,
          duration: this.duration,
          pkg: this.pkg,
          paymentGateway: this.selectedPaymentGateway,
        });
        this.$router.push('/signup');
      }
    },
  },
});
