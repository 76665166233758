
















import Vue from 'vue';

export default Vue.extend({
  name: 'PopupAlert',
  props: {
    alertIcon: {
      type: String,
      default: '',
    },
  },
  methods: {
    popupClose() {
      this.$emit('popup-close');
    },
  },
});
