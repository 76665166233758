var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-menu',{attrs:{"offset-y":_vm.isMobile,"close-on-content-click":false,"close-on-click":true,"min-width":_vm.menuWidth,"left":_vm.showMenuOnLeft,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._t("activator",null,{"on":on,"attrs":attrs})]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":""}},[_c('v-card-text',[_c('div',{staticClass:"flex"},[(_vm.selectedEvent.attributes)?_c('p',{staticClass:"text-black text-lg tracking-wide inline cursor-pointer underline text-blue-400"},[_c('router-link',{attrs:{"target":"_blank","to":{
                name: 'AppointmentCard',
                params: {
                  appointmentId: _vm.selectedEvent.attributes._id,
                  appointment: _vm.selectedEvent.attributes.originalEvent.event,
                },
              }}},[_vm._v(_vm._s(_vm.getEventTitle(_vm.selectedEvent)))])],1):_vm._e()]),_c('div',{staticClass:"flex flex-wrap content-start text-gray-500 inline py-2"},[_c('p',[_vm._v(" "+_vm._s(_vm.formatDate({ date: _vm.selectedEvent.start, dateFormat: _vm.DATE_FORMAT.ABRIDGED_DATE }))+" ")]),_c('p',{staticClass:"mx-1 text-lg -my-1 font-bold"},[_vm._v("⋅")]),_c('p',[_vm._v(_vm._s(_vm.formatDate({ date: _vm.selectedEvent.start, dateFormat: _vm.DATE_FORMAT.HOUR })))]),_c('p',{staticClass:"mx-1 -my-1 text-lg font-thin"},[_vm._v("-")]),_c('p',[_vm._v(_vm._s(_vm.formatDate({ date: _vm.selectedEvent.end, dateFormat: _vm.DATE_FORMAT.HOUR })))])])]),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showConfirmButton),expression:"showConfirmButton"}],staticClass:"m-0 animate-pulse",attrs:{"text":""},on:{"click":function($event){return _vm.onButtonClick('event:confirm')}}},[_c('p',{staticClass:"text-green-600"},[_vm._v(_vm._s(_vm.$t('button.common.confirm')))])]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCancelButton),expression:"showCancelButton"}],attrs:{"text":""},on:{"click":function($event){return _vm.onButtonClick('event:cancel')}}},[_c('p',{staticClass:"text-red-600"},[_vm._v(_vm._s(_vm.$t('button.common.cancel')))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }