

































































import Vue from 'vue';

export default Vue.extend({
  name: 'CropperZoom',
  components: {},
  props: {
    zoom: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener('mouseup', this.onStop, { passive: false });
    window.addEventListener('mousemove', this.onDrag, { passive: false });
    window.addEventListener('touchmove', this.onDrag, { passive: false });
    window.addEventListener('touchend', this.onStop, { passive: false });
  },
  destroyed() {
    window.removeEventListener('mouseup', this.onStop);
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('touchmove', this.onDrag);
    window.removeEventListener('touchend', this.onStop);
  },
  methods: {
    onDrag(e: any) {
      if (this.focus) {
        const position = e.touches ? e.touches[0].clientX : e.clientX;
        const line = this.$refs.line;
        if (line) {
          const { left, width } = (line as any).getBoundingClientRect();
          this.$emit('change', Math.min(1, Math.max(0, position - left) / width));
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    onStop() {
      this.focus = false;
    },
    onStart(e: any) {
      this.focus = true;
      this.onDrag(e);
    },
  },
});
