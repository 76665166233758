









import Vue from 'vue';

export default Vue.extend({
  name: 'GridButton',
  components: {},
  props: {
    buttonText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
