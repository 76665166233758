







































import Vue from 'vue';
import GridButton from '../Common/GridButton.vue';
import { EventBus } from '../../EventBus/EventBus';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import { focus } from 'vue-focus';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'ContactMethodIdStep',
  components: { GridButton, GridButtonLayout },
  directives: { focus },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    contactMethodName: {
      type: String,
      required: true,
    },
    contactMethodId: {
      type: String,
      required: false,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: '',
    };
  },
  computed: {},
  mounted() {
    const contactMethods = this.userData.contactMethods;
    const savedContactMethodId = contactMethods.length > 0 ? contactMethods[0].address : '';
    this.id = this.contactMethodId || savedContactMethodId || '';
  },
  methods: {
    handleEnterKeyPress(): void {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.emitContactMethodId();
      }
    },
    emitContactMethodId(): void {
      EventBus.$emit('step-forward', {
        value: this.id,
        emittedValueName: 'contactMethodId',
      });
    },
  },
  validations: {
    id: {
      required,
    },
  },
});
