



















import Vue from 'vue';
import { EventBus } from '../EventBus/EventBus';

export default Vue.extend({
  name: 'ProgressBar',
  components: {},
  props: {
    stepIndex: {
      type: Number,
      required: true,
    },
    stepTotal: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    progressPercent: {
      get(): number {
        const progressPercent = (this.stepIndex / this.stepTotal) * 100;
        return progressPercent;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {
    emitStepBackward(): void {
      if (this.stepIndex > 0) {
        EventBus.$emit('step-backward');
      }
    },
  },
});
