var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-calendar',{attrs:{"events":_vm.events,"calendar-focus-date":_vm.calendarFocusDate},on:{"change":_vm.getEvents,"mousedown:event":_vm.onMouseDownEvent,"mousedown:time":_vm.onMouseDownTime,"mousemove:time":_vm.onMouseMoveTime,"mouseup:time":_vm.onMouseUpTime,"mouseup:event":_vm.onMouseUpEvent,"mouseleave:event":_vm.onMouseLeaveNative,"click:event":_vm.onMouseUpEvent,"touchstart:event":_vm.onMouseDownEvent,"touchend:time":_vm.onMouseUpTime,"touchend:event":_vm.onMouseUpEvent,"update:calendar-focus-date":_vm.onCalendarFocusDateUpdate},scopedSlots:_vm._u([{key:"event",fn:function(ref){
var event = ref.event;
var timed = ref.timed;
return [(event.attributes.type == 'availableTime')?_c('available-time-editor',{attrs:{"event-id":event.attributes._id,"show-editor":_vm.showEditor,"selected-event-coord":_vm.selectedEventCoord,"selected-event":_vm.selectedEvent,"is-selected-event-saved":_vm.isSelectedEventSaved,"date-picker-date":_vm.datePickerDate},on:{"event:save":_vm.saveAvailableTime,"event:cancel":_vm.cancelAvailableTime,"event:delete":function($event){return _vm.deleteAvailableTime({ eventId: _vm.selectedEvent.attributes._id, deleteFromDb: true })},"date-picker:change":_vm.onDatePickerChange,"auto-complete-start:change":_vm.onAutoCompleteStartChange,"auto-complete-end:change":_vm.onAutoCompleteEndChange},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{ 'opacity-60': _vm.isPast(event.start) }},'div',attrs,false),on),[_c('div',{staticClass:"v-event-draggable"},[_c('span',[_vm._v(" "+_vm._s(_vm.getEventTitle(event))+" ")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.formatDate({ date: event.start, dateFormat: _vm.DATE_FORMAT.HOUR }))+" - "+_vm._s(_vm.formatDate({ date: event.end, dateFormat: _vm.DATE_FORMAT.HOUR })))])]),(timed)?_c('div',{class:{ 'v-event-drag-bottom': !_vm.isMobile },on:{"mousedown":function($event){$event.stopPropagation();return _vm.extendBottom(event)}}}):_vm._e()])]}}],null,true)}):_vm._e(),(event.attributes.type != 'availableTime')?_c('appointment-editor',{attrs:{"selected-event-coord":_vm.selectedEventCoord,"selected-event":_vm.selectedEvent},on:{"event:confirm":function($event){return _vm.updateAppointment($event, 'confirmed')},"event:cancel":function($event){return _vm.updateAppointment($event, 'cancelled')}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:{ 'opacity-60': _vm.isPast(event.start) }},'div',attrs,false),on),[_c('div',{staticClass:"v-event-draggable"},[_c('span',[_vm._v(" "+_vm._s(_vm.getEventTitle(event))+" ")]),_c('br'),_c('span',[_vm._v(_vm._s(_vm.formatDate({ date: event.start, dateFormat: _vm.DATE_FORMAT.HOUR }))+" - "+_vm._s(_vm.formatDate({ date: event.end, dateFormat: _vm.DATE_FORMAT.HOUR })))])])])]}}],null,true)}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }