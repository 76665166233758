












import Vue from 'vue';
import GridBaseLayout from './GridBaseLayout.vue';

export default Vue.extend({
  name: 'GridButtonLayout',
  components: { GridBaseLayout },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    mainClass: {
      type: String,
      required: false,
      default: '',
    },
    paddingClass: {
      type: String,
      required: false,
      default: 'h-30v lg:h-40v',
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
