var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.visiblePackageTransactions),function(packageTransaction){return _c('router-link',{key:packageTransaction._id,attrs:{"to":{
      name: 'ExtendedAppointmentCalendar',
      params: {
        packageTransaction: packageTransaction,
        userId: packageTransaction.hostedById,
        packageTransactionId: packageTransaction._id,
      },
    }}},[_c('div',{staticClass:"bg-white border-l-4 px-4 pt-3 pb-5 border-solid shadow-md rounded-lg rounded-l-none mb-4 h-auto w-full hover:bg-gray-100 text-left",class:{
        'border-green-300 ': packageTransaction.status == 'pending',
        'border-black': packageTransaction.status == 'completed',
        'border-green-600': packageTransaction.status == 'confirmed',
        'border-red-300': packageTransaction.status == 'cancelled',
      }},[_c('p',{staticClass:"leading-relaxed uppercase text-gray-600 tracking-wide"},[_vm._v(" "+_vm._s(_vm.getPackageName(packageTransaction.packageData))+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex flex-col flex-1"},[_c('div',{staticClass:"flex space-x-1 py-1"},[_c('p',{staticClass:"text-lg"},[_vm._v(" "+_vm._s(_vm.$t('appointment.remainingAppointments', { remainingAppointments: packageTransaction.remainingAppointments, totalAppointments: packageTransaction.packageData.lessonAmount, }))+" ")])]),_c('p',{staticClass:"text-sm text-gray-400 py-1"},[_vm._v(" "+_vm._s(_vm.$t(("localeCode." + (packageTransaction.lessonLanguage))))+" / "+_vm._s(_vm.$t('userProfile.teacher.lessonSelection.price', { lessonDuration: packageTransaction.lessonDuration, lessonAmount: packageTransaction.packageData.lessonAmount, }))+" ")])]),(packageTransaction)?_c('img',{staticClass:"rounded-full h-12",attrs:{"src":packageTransaction.hostedByData.profileImageUrl}}):_vm._e()])])])}),(!_vm.isAdmin && !_vm.isTeacher && _vm.visiblePackageTransactions.length == 0)?_c('div',{staticClass:"bg-white p-4 border-solid shadow-md rounded-md mb-4 h-auto w-full"},[_c('div',[_c('i18n',{attrs:{"path":"dashboard.findTeacher","tag":"label"}},[_c('a',{staticClass:"text-blue-500",attrs:{"href":"mailto:support@manabu.sg"}},[_vm._v("support@manabu.sg")])]),_c('router-link',{staticClass:"bg-green-500 text-white rounded-lg p-2 my-2 block",attrs:{"to":"/teachers","tag":"button"}},[_vm._v(" Find a teacher ")])],1)]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }