var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('cropper',{ref:"cropper",staticClass:"twitter-cropper",attrs:{"background-class":"twitter-cropper__background","foreground-class":"twitter-cropper__foreground","image-restriction":"stencil","stencil-size":_vm.stencilSize,"stencil-props":{
      lines: {},
      handlers: {},
      movable: false,
      scalable: false,
      aspectRatio: 1,
      previewClass: 'twitter-cropper__stencil',
    },"transitions":false,"debounce":false,"default-size":_vm.defaultSize,"min-width":150,"min-height":150,"src":_vm.src},on:{"change":_vm.onChange}}),_c('cropper-zoom',{attrs:{"zoom":_vm.zoom},on:{"change":_vm.onZoom}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }