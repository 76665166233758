





















































































import Vue from 'vue';
import { PackageDoc } from '../../../../server/models/Package';
import { mapGetters } from 'vuex';
import ProgressBar from '../ProgressBar/ProgressBar.vue';
import TeacherPackageButton from './TeacherPackageButton.vue';
import AppointmentCalendar from '../Calendar/AppointmentCalendar.vue';
import { EventBus } from '../EventBus/EventBus';
import { TranslateResult } from 'vue-i18n';
import LessonDurationButton from './LessonDurationButton.vue';
import { StringKeyObject } from '../../../../server/types/custom';
import PaymentCard from './PaymentCard.vue';
import { ls } from '@/store/plugins';
import LeftCardLayout from './Layouts/LeftCardLayout.vue';

export default Vue.extend({
  name: 'TeacherPackagesCard',
  components: {
    ProgressBar,
    TeacherPackageButton,
    AppointmentCalendar,
    LessonDurationButton,
    PaymentCard,
    LeftCardLayout,
  },
  props: {
    teacher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      stepIndex: 0,
      selectedPackage: {} as StringKeyObject,
      selectedLessonDuration: 60,
      selectedTimeslots: [] as string[],
      selectedPackagePrice: 0,
    };
  },
  computed: {
    ...mapGetters({
      currency: 'user/currency',
    }),
    dialogButtonClass: {
      get(): string {
        const dialogButtonClass = 'w-11/12 md:w-6/12 lg:w-4/12 mx-auto';
        return dialogButtonClass;
      },
    },
    visiblePackages: {
      get(): PackageDoc[] {
        const visiblePackages = this.teacher.teacherData.packages
          .filter((pkg: PackageDoc) => {
            return pkg.isOffering;
          })
          .sort(
            (a: PackageDoc, b: PackageDoc) =>
              b.type.localeCompare(a.type) || a.lessonAmount - b.lessonAmount
          );
        return visiblePackages;
      },
    },
    lessonDurations: {
      get(): number[] {
        const lessonDurations = this.selectedPackage ? this.selectedPackage.lessonDurations : [];
        return lessonDurations;
      },
    },
    stepTitle: {
      get(): TranslateResult | string {
        const stepTitles = [
          this.$t('userProfile.teacher.lessonSelection.choose'),
          this.$t('userProfile.teacher.lessonSelection.options'),
          this.$t('userProfile.teacher.lessonSelection.times'),
          'Payment',
        ];
        return stepTitles[this.stepIndex];
      },
    },
  },
  created() {
    EventBus.$on('step-backward', this.handleStepBackward());
    const paymentData = ls.get('paymentData');
    if (paymentData) {
      const { timeslots, duration, pkg } = paymentData;
      this.selectedPackage = timeslots;
      this.selectedLessonDuration = duration;
      this.selectedPackage = pkg;
      this.stepIndex = 3;
      this.showDialog = true;
    }
    return;
  },
  methods: {
    onSubmitTimeslots(timeslots: string[]): void {
      this.selectedTimeslots = timeslots;
      this.stepIndex++;
    },
    onPackageClick(pkg: PackageDoc): void {
      this.showDialog = true;
      this.stepIndex = 1;
      this.selectedPackage = pkg;
    },
    onLessonDurationClick(lessonDurationObj: StringKeyObject): void {
      const { lessonDuration, packagePrice } = lessonDurationObj;
      this.stepIndex++;
      this.selectedLessonDuration = lessonDuration;
      this.selectedPackagePrice = packagePrice;
    },
    onCloseDialog(): void {
      this.selectedPackage = {};
      this.stepIndex = 0;
      this.showDialog = false;
      ls.remove('paymentData');
    },
    handleStepBackward(): () => void {
      const self = this;
      return function () {
        self.stepIndex--;
      };
    },
  },
});
