








































































import Vue from 'vue';
import ExtendedCropper from '../Cropper/ExtendedCropper.vue';
import { Preview } from 'vue-advanced-cropper';
import { StringKeyObject } from '../../../../server/types/custom';
import { makeGoogleCloudStorageMixin } from '../../mixins/googleCloudStorage';
import { TranslateResult } from 'vue-i18n';
const googleCloudStorageMixin = makeGoogleCloudStorageMixin;

export default Vue.extend({
  name: 'ProfileImage',
  components: { ExtendedCropper, Preview },
  mixins: [googleCloudStorageMixin],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      profileImage: {
        src: '',
        cropperPreview: {},
        isSaved: false,
      } as StringKeyObject,
      isEditingProfileImage: false,
      showDialog: false,
    };
  },
  computed: {
    showProfileImage: {
      get(): boolean {
        return !this.isEditingProfileImage && !this.profileImage.isSaved;
      },
    },
    showPreviewImage: {
      get(): boolean {
        return this.isEditingProfileImage || this.profileImage.isSaved;
      },
    },
    dialogButtonText: {
      get(): TranslateResult {
        const dialogButtonText = this.isEditingProfileImage
          ? this.$t('button.common.save')
          : this.$t('button.common.upload');
        return dialogButtonText;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {
    resetProfileImage(): void {
      this.profileImage = {
        src: '',
        cropperPreview: {},
        isSaved: false,
      };
      this.isEditingProfileImage = false;
      this.showDialog = false;
      (this as any).$refs.imageUploader.value = '';
    },
    updateCropperPreview(result: any) {
      this.profileImage.cropperPreview = result;
    },
    onDialogButtonClick(): void {
      if (!this.isEditingProfileImage) {
        (this as any).$refs.imageUploader.click();
      } else {
        this._saveProfileImage();
      }
    },
    onFileSelect(event: any) {
      const { files } = event.target;
      if (files && files[0]) {
        if (this.profileImage.src) {
          URL.revokeObjectURL(this.profileImage.src);
        }
        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e: any) => {
          this.profileImage.src = blob;
        };
        reader.readAsArrayBuffer(files[0]);
        this.isEditingProfileImage = true;
      }
    },
    _saveProfileImage(): void {
      const cropper = (this as any).$refs.extendedCropper.$refs.cropper;
      const { canvas, image } = cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob: Blob): void => {
          this.profileImage.src = image.src;
          this._uploadBlobToStorage(blob);
        });
      }
      this.profileImage.isSaved = true;
      this.resetProfileImage();
    },
    async _uploadBlobToStorage(blob: Blob): Promise<void> {
      const blobType = blob.type;
      const metaData = {
        contentType: blobType,
      };
      const fileType = blob.type.split('/')[1];
      const userId = this.userData._id;
      (this as any).updateUserAfterUpload({
        file: blob,
        metaData,
        cloudFilePath: `${userId}/images/profileImage.${fileType}`,
        userId,
        updateParamName: 'profileImageUrl',
        repositoryName: 'user',
      });
    },
  },
});
