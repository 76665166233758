









































































































































import LeftCardLayout from '@/components/UserProfile/Layouts/LeftCardLayout.vue';
import TwoCardLayout from '@/components/UserProfile/Layouts/TwoCardLayout.vue';
import { makeDateMixin } from '@/mixins/date';
import { makePackageMixin } from '@/mixins/package';
import { makeAppointmentRepository } from '@/repositories/appointment';
import { AppointmentDoc } from '@server/models/Appointment';
import { StringKeyObject } from '@server/types/custom';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { vueTopprogress } from 'vue-top-progress';
import { mapGetters } from 'vuex';
const appointmentRepository = makeAppointmentRepository;

export default Vue.extend({
  name: 'AppointmentCard',
  components: {
    TwoCardLayout,
    LeftCardLayout,
    vueTopprogress,
  },
  mixins: [makeDateMixin, makePackageMixin],
  props: {
    appointment: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  data() {
    return {
      appointmentModel: {} as StringKeyObject,
    };
  },
  asyncComputed: {
    appointmentData: {
      async get(): Promise<any> {
        let appointmentData;
        if (this.appointmentModel._id) {
          appointmentData = this.appointmentModel;
        } else if (this.appointment._id) {
          appointmentData = this.appointment;
        } else {
          this.appointmentModel = await this.getAppointment();
          appointmentData = this.appointmentModel;
        }
        return appointmentData;
      },
    },
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
      isTeacher: 'user/isTeacher',
    }),
    columnData: {
      get(): StringKeyObject[] {
        const self = this as any;
        const columnData = [
          [
            {
              title: this.$t('appointment.language'),
              text: this.$t(
                `localeCode.${self.appointmentData.packageTransactionData.lessonLanguage}`
              ),
            },
            {
              title: this.$t('appointment.commTool'),
              text: this.$t(`contactMethod.${self.appointmentData.locationData.name}`),
            },
          ],
          [
            {
              title: this.$t('onboarding.package.lessonDuration'),
              text: this.$t('appointment.lessonDuration', {
                lessonDuration: self.appointmentData.packageTransactionData.lessonDuration,
              }),
            },
            {
              title: this.teacherAccount,
              text: self.appointmentData.locationData.hostedByContactMethod.address,
            },
          ],
          [
            {
              title: this.$t(`appointment.status.title`),
              text: this.$t(`appointment.status.${self.appointmentData.status}`),
            },
            {
              title: this.studentAccount,
              text: self.appointmentData.locationData.reservedByContactMethod.address,
            },
          ],
        ];
        return columnData;
      },
    },
    teacherAccount: {
      get(): TranslateResult {
        const teacherAccount = this.getAccountId(true);
        return teacherAccount;
      },
    },
    studentAccount: {
      get(): TranslateResult {
        const studentAccount = this.getAccountId(false);
        return studentAccount;
      },
    },
    userEdgeData: {
      get(): { name: string; role: TranslateResult; profileImageUrl: string } {
        const self = this as any;
        const isHostedBy = this.userData._id == self.appointmentData.hostedById;
        const { hostedByData, reservedByData } = self.appointmentData;
        const userEdgeData = isHostedBy
          ? {
              name: reservedByData.name,
              role: this.$t('userProfile.role.user'),
              profileImageUrl: reservedByData.profileImageUrl,
            }
          : {
              name: hostedByData.name,
              role: this.$t(`userProfile.teacher.${hostedByData.teacherData.type}`),
              profileImageUrl: hostedByData.profileImageUrl,
            };
        return userEdgeData;
      },
    },
    isAppointmentCancelled: {
      get(): boolean {
        const self = this as any;
        const isAppointmentCancelled = self.appointmentData.status == 'cancelled';
        return isAppointmentCancelled;
      },
    },
    isAppointmentConfirmed: {
      get(): boolean {
        const self = this as any;
        const isAppointmentConfirmed = self.appointmentData.status == 'confirmed';
        return isAppointmentConfirmed;
      },
    },
  },
  mounted() {
    return;
  },
  methods: {
    async getAppointment(): Promise<AppointmentDoc> {
      const appointmentId = this.$route.params.appointmentId;
      const getAppointmentRes = await appointmentRepository.getById({
        _id: appointmentId,
        query: {},
      });
      const { data } = getAppointmentRes;
      const { appointment } = data;
      return appointment;
    },
    getAccountId(isHostedBy: boolean): TranslateResult {
      const self = this as any;
      const locationData = self.appointmentData.locationData;
      const isAlternate = locationData.name && locationData.name == 'alternative';
      const contactMethod = isHostedBy
        ? locationData.hostedByContactMethod
        : locationData.reservedByContactMethod;
      const accountTitle = isHostedBy
        ? this.$t('appointment.teacherAccount')
        : this.$t('appointment.studentAccount');
      const studentAccount = isAlternate
        ? `${accountTitle} (${this.$t(`contactMethod.${contactMethod.name}`)})`
        : accountTitle;
      return studentAccount;
    },
    async cancelAppointment(): Promise<void> {
      await this.updateAppointment('cancelled');
    },
    async confirmAppointment(): Promise<void> {
      await this.updateAppointment('confirmed');
    },
    async updateAppointment(status: string): Promise<void> {
      const self = this as any;
      self.$refs.topProgress.start();
      const appointment = await this.$store.dispatch('appointment/updateAppointment', {
        appointmentId: self.appointmentData._id,
        updateParams: {
          status,
        },
      });
      this.appointmentModel = appointment;
      self.$refs.topProgress.done();
    },
  },
});
