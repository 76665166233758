
















































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import ExtendedAccountDropdown from '../AccountDropdown/ExtendedAccountDropdown.vue';
import BasicAccountDropdown from '../AccountDropdown/BasicAccountDropdown.vue';
import BasicAccountDropdownLink from '../AccountDropdown/BasicAccountDropdownLink.vue';

type RouterLink = {
  title: TranslateResult;
  path: string;
  isShowing: boolean;
};

export default Vue.extend({
  name: 'Navbar',
  components: {
    ExtendedAccountDropdown,
    BasicAccountDropdown,
    BasicAccountDropdownLink,
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      userData: 'user/entityStateData',
    }),
    outerRouterLinks: {
      get: function (): RouterLink[] {
        return [
          {
            title: this.$t('nav.becomeTeacher'),
            path: '/apply',
            isShowing: !this.isLoggedIn,
          },
          {
            title: this.$t('nav.findTeacher'),
            path: '/teachers',
            isShowing: true,
          },
          {
            title: this.$t('nav.login'),
            path: '/login',
            isShowing: !this.isLoggedIn,
          },
          {
            title: this.$t('nav.signup'),
            path: '/signup',
            isShowing: !this.isLoggedIn,
          },
        ];
      },
    },
    innerRouterLinks: {
      get: function (): RouterLink[] {
        return [
          {
            title: this.$t('nav.profile'),
            path: `/user/${this.userData._id}`,
            isShowing: this.isLoggedIn,
          },
          {
            title: this.$t('nav.settings'),
            path: '/onboarding',
            isShowing: this.isLoggedIn,
          },
          {
            title: this.$t('nav.logout'),
            path: '/logout',
            isShowing: this.isLoggedIn,
          },
        ];
      },
    },
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      this.showDropdown = false;
    },
  },
});

export { RouterLink };
