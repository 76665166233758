




























import Vue from 'vue';
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  History,
} from 'tiptap-vuetify';
import GridButton from '../Common/GridButton.vue';
import GridButtonLayout from '../Layouts/GridButtonLayout.vue';
import { EventBus } from '../../EventBus/EventBus';
import { required } from 'vuelidate/lib/validators';

export default Vue.extend({
  name: 'ProfileBioStep',
  components: { TiptapVuetify, GridButton, GridButtonLayout },
  props: {
    stepTitle: {
      type: String,
      required: true,
    },
    profileBio: {
      type: String,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      extensions: [History, Bold, Underline, Italic, ListItem, BulletList, OrderedList],
      bio: this.profileBio || this.userData.profileBio || '',
    };
  },
  computed: {},
  watch: {
    bio: function (newVal) {
      const isEmpty = newVal == '<p></p>';
      if (isEmpty) {
        this.bio = '';
      }
    },
  },
  mounted() {
    return;
  },
  methods: {
    emitStepForward(): void {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        EventBus.$emit('step-forward', { value: this.bio, emittedValueName: 'profileBio' });
      }
    },
  },
  validations: {
    bio: {
      required,
    },
  },
});
