







































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { StringKeyObject } from '../../../../server/types/custom';
import { SUPPORTED_LOCALES } from '../../plugins/i18n';

export default Vue.extend({
  name: 'Footer',
  data() {
    return {
      currentYear: new Date().getFullYear(),
      SUPPORTED_LOCALES,
      SUPPORTED_CURRENCIES: Object.freeze({
        SGD: '$ SGD',
        USD: '$ USD',
        JPY: '¥ JPY',
      }),
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
    }),
    userId: {
      get(): string {
        const userId = this.userData._id;
        return userId;
      },
    },
    userSettings: {
      get(): StringKeyObject {
        const userSettings = this.userData.settings;
        return userSettings;
      },
    },
    userLocale: {
      get(): string {
        const userLocale = this.userData.settings.locale;
        return userLocale;
      },
      set(locale: string): void {
        const userId = this.userId;
        const settings = this.userSettings;
        this.$store.dispatch('user/updateLocale', {
          locale,
          userId,
          settings,
          currentVueComponent: this,
        });
      },
    },
    userCurrency: {
      get(): string {
        const userCurrency = this.userData.settings.currency;
        return userCurrency;
      },
      set(currency: string): void {
        const userId = this.userId;
        const settings = this.userSettings;
        this.$store.dispatch('user/updateCurrency', {
          currency,
          userId,
          settings,
        });
      },
    },
    iconData: {
      get() {
        return [
          {
            link: 'https://www.facebook.com/ManabuOfficial/',
            class: 'fab fa-facebook-f',
            name: 'Facebook',
          },
          {
            link: 'https://twitter.com/lessonsmanabu',
            class: 'fab fa-twitter',
            name: 'Twitter',
          },
          {
            link: 'https://www.instagram.com/nihongo_manabu_/',
            class: 'fab fa-instagram',
            name: 'Instagram',
          },
          {
            link: 'https://www.youtube.com/channel/UCwtTZCZ9apsj7zNn7n1eS5w',
            class: 'fab fa-youtube',
            name: 'Youtube',
          },
          {
            link: 'https://line.me/R/ti/p/%40743ilrvx',
            class: 'fab fa-line',
            name: 'LINE',
          },
          {
            link: 'https://discord.com/invite/zHpyvN2TVA',
            class: 'fab fa-discord',
            name: 'Discord',
          },
          {
            link: 'https://api.whatsapp.com/message/RJYZPGP6LNXNF1',
            class: 'fab fa-whatsapp',
            name: 'WhatsApp',
          },
        ];
      },
    },
    usefulLinkData: {
      get() {
        return [
          {
            link: '/apply',
            title: this.$t('nav.becomeTeacher'),
          },
          {
            link: '/teachers',
            title: this.$t('nav.findTeacher'),
          },
        ];
      },
    },
  },
});
