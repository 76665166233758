



























import Vue from 'vue';
import FadeInOut from '../FadeInOut/FadeInOut.vue';
import { mapGetters } from 'vuex';
import BasicAccountDropdown from './BasicAccountDropdown.vue';

export default Vue.extend({
  name: 'ExtendedAccountDropdown',
  components: { FadeInOut, BasicAccountDropdown },
  props: {
    innerRouterLinks: {
      required: false,
      type: Array,
    },
  },
  data() {
    return {
      showDropdown: false,
      isHovering: false,
    };
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
    }),
  },
  mounted() {
    return;
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    toggleHover() {
      this.isHovering = !this.isHovering;
    },
    closeDropdown(event: any) {
      const itemClicked = event!.target.tagName.toLowerCase();
      const isClickingProfileImage = ['img', 'button'].includes(itemClicked);
      if (this.showDropdown && !isClickingProfileImage) {
        this.showDropdown = false;
      }
    },
  },
});
