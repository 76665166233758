
























































import Vue from 'vue';
import dayjs from 'dayjs';
import { JoinedUserDoc } from '../../../../server/models/User';
import LanguageBars from './LanguageBars.vue';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TranslateResult } from 'vue-i18n';
import DOMPurify from 'dompurify';
import { StringKeyObject } from '../../../../server/types/custom';
import LeftCardLayout from './Layouts/LeftCardLayout.vue';
import { mapGetters } from 'vuex';
import { makeTeacherRepository } from '@/repositories/teacher';
import { makeUserRepository } from '@/repositories/user';
dayjs.extend(relativeTime);

const teacherRepository = makeTeacherRepository;
const userRepository = makeUserRepository;

export default Vue.extend({
  name: 'ProfileBioCard',
  components: { LanguageBars, LeftCardLayout },
  props: {
    user: {
      type: Object,
      required: true,
    },
    isTeacher: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      hasApproved: false,
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
    }),
    lastOnlineDate: {
      get(): string {
        const lastOnlineDate = dayjs().to(dayjs(this.user.lastOnlineDate));
        return lastOnlineDate;
      },
    },
    userRole: {
      get(): TranslateResult {
        const userRole = this.isTeacher
          ? this.$t(`userProfile.teacher.${this.user.teacherData!.type}`)
          : this.$t('userProfile.role.user');
        return userRole;
      },
    },
    sanitizedProfileBio: {
      get(): string {
        const sanitizedProfileBio = DOMPurify.sanitize(this.user.profileBio!);
        return sanitizedProfileBio;
      },
    },
    languages: {
      get(): JoinedUserDoc['languages'] {
        const languages = this.user.languages;
        return languages;
      },
    },
    targetLanguage: {
      get(): StringKeyObject {
        const languages = this.languages;
        const targetLanguage = languages[0];
        return targetLanguage;
      },
    },
    targetLanguagePrependText: {
      get(): TranslateResult {
        const targetLanguagePrependText = this.isTeacher
          ? this.$t('userProfile.teacher.teaches')
          : this.$t('userProfile.learning');
        return targetLanguagePrependText;
      },
    },
    nonTargetLanguage: {
      get(): StringKeyObject {
        const languages = this.languages;
        const nonTargetLanguage = languages[1];
        return nonTargetLanguage;
      },
    },
  },
  created() {
    if (this.isTeacher) {
      this.hasApproved = this.user.teacherData.applicationStatus == 'approved';
    }
  },
  methods: {
    async approveTeacher(): Promise<void> {
      try {
        this.hasApproved = true;
        await teacherRepository.updateById({
          _id: this.user.teacherData._id,
          updateParams: {
            approvalDate: new Date(),
            applicationStatus: 'approved',
          },
        });
        await userRepository.updateById({
          _id: this.user._id,
          updateParams: {
            role: 'teacher',
          },
        });
      } catch (err) {
        this.hasApproved = false;
        throw err;
      }
    },
  },
});
