


















import AppointmentCards from '@/components/Dashboard/Cards/AppointmentCards.vue';
import PackageTransactionCards from '@/components/Dashboard/Cards/PackageTransactionCards.vue';
import { ls } from '@/store/plugins';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { mapGetters } from 'vuex';
import EditCalendar from '@/components/Calendar/EditCalendar.vue';
import UsersCard from '@/components/Dashboard/Cards/UsersCard.vue';
import ProfileImageCard from '@/components/UserProfile/ProfileImageCard.vue';

export default Vue.extend({
  name: 'Dashboard',
  components: {
    EditCalendar,
    ProfileImageCard,
    UsersCard,
    AppointmentCards,
    PackageTransactionCards,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
      isFinishedOnboarding: 'user/isFinishedOnboarding',
      isTeacher: 'user/isTeacher',
      isAdmin: 'user/isAdmin',
    }),
    usersCardTitle: {
      get(): TranslateResult {
        let usersCardTitle;
        if (this.isAdmin) {
          usersCardTitle = this.$t('dashboard.admin.pendingTeachers');
        } else if (this.isTeacher) {
          usersCardTitle = this.$t('dashboard.myStudents');
        } else {
          usersCardTitle = this.$t('dashboard.myTeachers');
        }
        return usersCardTitle;
      },
    },
  },
  async created() {
    if (!this.isFinishedOnboarding) {
      return this.$router.push('/onboarding');
    }
    const paymentData = ls.get('paymentData');
    const paymentGatewaySuccess = ls.get('paymentGatewaySuccess');
    const { teacher } = paymentData;
    if (paymentData) {
      this.$router.push({ path: `/user/${teacher._id}` });
    }
    if (paymentGatewaySuccess) {
      ls.remove('paymentGatewaySuccess');
      this.$store.dispatch('appointment/resetEntityState');
      await this.$store.dispatch('appointment/getEntityStateData');
    }
  },
  methods: {},
});
