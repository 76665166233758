
















import Vue from 'vue';

export default Vue.extend({
  name: 'TwoCardLayout',
  components: {},
  props: {
    rightCardClass: {
      type: String,
      default: 'max-h-96',
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
