






import Vue from 'vue';

export default Vue.extend({
  name: 'LeftCardLayout',
  components: {},
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {
    return;
  },
  methods: {},
});
