


















































import { makeDateMixin } from '@/mixins/date';
import { AppointmentDoc } from '@server/models/Appointment';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'AppointmentCards',
  components: {},
  mixins: [makeDateMixin],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
      appointments: 'appointment/entityStateData',
      locale: 'user/locale',
    }),
    // visibleAppointments: {
    //   get(): AppointmentDoc[] {
    //     const visibleAppointments = this.appointments.slice(0, 3);
    //     return visibleAppointments;
    //   },
    // },
  },
  // async created() {
  // },
  methods: {
    getProfileImageUrl(appointment: AppointmentDoc): string | undefined {
      const isHostedBy = this.userData._id == appointment.hostedById;
      const { hostedByData, reservedByData } = appointment;
      let profileImageUrl = isHostedBy
        ? reservedByData.profileImageUrl
        : hostedByData.profileImageUrl;
      return profileImageUrl;
    },
  },
});
