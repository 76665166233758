


















import Vue from 'vue';
import NavbarComponent from '../Navbar/Navbar.vue';
import FooterComponent from '../Footer/Footer.vue';
import ErrorBoundaryComponent from '../ErrorBoundary/ErrorBoundary.vue';

export default Vue.extend({
  name: 'LayoutDefault',
  components: {
    NavbarComponent,
    FooterComponent,
    ErrorBoundaryComponent,
  },
});
