












































































import { makePackageMixin } from '@/mixins/package';
import { PackageTransactionDoc } from '@server/models/PackageTransaction';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'PackageTransactionCards',
  components: {},
  mixins: [makePackageMixin],
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      userData: 'user/entityStateData',
      isTeacher: 'user/isTeacher',
      isAdmin: 'user/isAdmin',
      packageTransactions: 'packageTransaction/entityStateData',
    }),
    visiblePackageTransactions: {
      get(): PackageTransactionDoc[] {
        const incompletePackageTransactions = this.packageTransactions.filter(
          (packageTransaction: PackageTransactionDoc) => {
            return (
              packageTransaction.remainingAppointments > 0 &&
              this.userData._id == packageTransaction.reservedById
            );
          }
        );
        // const visiblePackageTransactions = incompletePackageTransactions.slice(0, 3);
        // return visiblePackageTransactions;
        return incompletePackageTransactions;
      },
    },
  },
  methods: {
    getProfileImageUrl(packageTransaction: PackageTransactionDoc): string | undefined {
      const isHostedBy = this.userData._id == packageTransaction.hostedById;
      const { hostedByData, reservedByData } = packageTransaction;
      let profileImageUrl = isHostedBy
        ? reservedByData.profileImageUrl
        : hostedByData.profileImageUrl;
      return profileImageUrl;
    },
  },
});
